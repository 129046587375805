import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, organizationDataSourcesUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";
import {
  OrdersRecordType,
  DocumentsRecordType,
  VendorOrdersRecordType,
  MessagesRecordType,
  ChatsRecordType,
  NotesRecordType,
  WorkflowTasksRecordType,
  WorkflowRulesRecordType,
  WorkflowNotificationsRecordType,
  OrderDocumentRecordType,
  AuditLogsRecordType,
  DocumentLogRecordType,
  SharedDataLogRecordType,
  WorkflowLogRecordType,
  UserSearchSettingsLogRecordType,
  UserAccessSettingsLogRecordType,
  UserConfigSettingsLogRecordType,
} from "@/lib/settings";

export default {
  state: {
    organizationDataSources: [],
    cachedOrganizationDataSourcesProductRootId: -1,
    cachedOrganizationDataSourcesOrganizationRootId: -1,
  },
  getters: {
    getOrganizationDataSourceByRootId: (state) => (rootId) => {
      const organizationDataSource = state.organizationDataSources?.find((organizationDataSource) => Number(organizationDataSource?.RootId || 0) === Number(rootId || 0)) || null;
      return organizationDataSource?.RootId > 0 ? organizationDataSource : null;
    },
    getFilteredOrganizationDataSources: (state) => (params) => {
      let result = [];
      const productRootId = Number(params?.ProductRootId || 0);
      const documentTypesOnly = params?.DocumentTypesOnly || false;
      state.organizationDataSources?.forEach((organizationDataSource) => {
        if (
          (organizationDataSource.ProductRootId === productRootId && !documentTypesOnly) ||
          (organizationDataSource.Type === DocumentsRecordType && !organizationDataSource.Name?.toLowerCase().includes("dropbox"))
        ) {
          switch (organizationDataSource.Type || "") {
            case OrdersRecordType:
            case VendorOrdersRecordType:
            case DocumentsRecordType:
            case WorkflowTasksRecordType:
            case WorkflowRulesRecordType:
            case WorkflowNotificationsRecordType:
              result.push(_.cloneDeep(organizationDataSource));
              break;
            case MessagesRecordType:
            case ChatsRecordType:
            case NotesRecordType:
            case OrderDocumentRecordType:
            case AuditLogsRecordType:
            case DocumentLogRecordType:
            case SharedDataLogRecordType:
            case WorkflowLogRecordType:
            case UserSearchSettingsLogRecordType:
            case UserAccessSettingsLogRecordType:
            case UserConfigSettingsLogRecordType:
              break;
          }
        }
      });
      return result;
    },
  },
  mutations: {
    setOrganizationDataSourceDataSources(state, Data) {
      state.organizationDataSources = Data?.sort((ods1, ods2) => (ods1.Name > ods2.Name ? 1 : -1)) || [];
    },
    clearOrganizationDataSourceDataSources(state) {
      state.organizationDataSources = [];
      state.cachedOrganizationDataSourcesProductRootId = -1;
      state.cachedOrganizationDataSourcesOrganizationRootId = -1;
    },
    setCachedOrganizationDataSourcesProductRootId(state, cachedOrganizationDataSourcesProductRootId) {
      state.cachedOrganizationDataSourcesProductRootId = Number(cachedOrganizationDataSourcesProductRootId || -1);
    },
    setCachedOrganizationDataSourcesOrganizationRootId(state, cachedOrganizationDataSourcesOrganizationRootId) {
      state.cachedOrganizationDataSourcesOrganizationRootId = Number(cachedOrganizationDataSourcesOrganizationRootId || -1);
    },
    _addOrganizationDataSource(state, organizationDataSource) {
      if (organizationDataSource?.RootId > 0) {
        state.organizationDataSources.push(organizationDataSource);
        state.organizationDataSources = state.organizationDataSources.sort((ods1, ods2) => (ods1.Name > ods2.Name ? 1 : -1)) || [];
      }
    },
    _updateOrganizationDataSource(state, updateOrganizationDataSource) {
      const rootId = Number(updateOrganizationDataSource?.RootId || 0);
      if (rootId > 0) {
        let organizationDataSourcesData = _.cloneDeep(state.organizationDataSources) || [];
        organizationDataSourcesData =
          [...organizationDataSourcesData.filter((organizationDataSource) => Number(organizationDataSource?.RootId || 0) !== rootId), _.cloneDeep(updateOrganizationDataSource)] ||
          [];
        state.organizationDataSources = organizationDataSourcesData.sort((ods1, ods2) => (ods1.Name > ods2.Name ? 1 : -1)) || [];
      }
    },
    _updateOrganizationDataSourceIsStandardDataSource(state, updateOrganizationDataSource) {
      const rootId = Number(updateOrganizationDataSource?.RootId || 0);
      if (rootId > 0) {
        let organizationDataSource = state.organizationDataSources?.find((organizationDataSource) => Number(organizationDataSource?.RootId || 0) === rootId);
        if (organizationDataSource?.RootId > 0) {
          organizationDataSource.IsStandardDataSource = updateOrganizationDataSource.IsStandardDataSource || false;
        }
      }
    },
    _removeOrganizationDataSource(state, deleteOrganizationDataSource) {
      const rootId = Number(deleteOrganizationDataSource?.RootId || 0);
      if (rootId > 0) {
        let organizationDataSourcesData = _.cloneDeep(state.organizationDataSources) || [];
        organizationDataSourcesData = [...organizationDataSourcesData.filter((organizationDataSource) => Number(organizationDataSource?.RootId || 0) !== rootId)] || [];
        state.organizationDataSources = organizationDataSourcesData.sort((ods1, ods2) => (ods1.Name > ods2.Name ? 1 : -1)) || [];
      }
    },
  },
  actions: {
    async getOrganizationDataSources(context, { ProductRootId, OrganizationRootId, UseCache, ValidateRecords }) {
      await timeOperation(async () => {
        UseCache = UseCache || false;
        ValidateRecords = ValidateRecords || false;
        ProductRootId = Number(ProductRootId || 0);
        OrganizationRootId = Number(OrganizationRootId || 0);
        const cachedProductRootId = Number(context.state.cachedOrganizationDataSourcesProductRootId || 0);
        const cachedOrganizationRootId = Number(context.state.cachedOrganizationDataSourcesOrganizationRootId || 0);
        const validData = context.state.organizationDataSources?.length > 0;
        const dirtyCache = !validData || ProductRootId !== cachedProductRootId || OrganizationRootId !== cachedOrganizationRootId;
        if (!UseCache || dirtyCache) {
          context.commit("setCachedOrganizationDataSourcesProductRootId", ProductRootId);
          context.commit("setCachedOrganizationDataSourcesOrganizationRootId", OrganizationRootId);
          const formattedUrl = `${organizationDataSourcesUrl}?productrootid=${ProductRootId}&organizationrootid=${OrganizationRootId}&productdatasourcerootid=0&validaterecords=${ValidateRecords}&includecustomfields=true&includemetadata=true`;
          const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
          context.commit("setOrganizationDataSourceDataSources", data);
        }
      }, "organizationDataSources - getOrganizationDataSources");
    },
    async addOrganizationDataSource(context, organizationDataSource) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: organizationDataSourcesUrl, Payload: organizationDataSource });
        if (data?.RootId > 0) {
          organizationDataSource.RootId = data.RootId;
          context.commit("_addOrganizationDataSource", data);
        }
        return data;
      }, "organizationDataSources - addOrganizationDataSource");
    },
    async updateOrganizationDataSource(context, organizationDataSource) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDataSourcesUrl}/${Number(organizationDataSource?.RootId || 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationDataSource });
        context.commit("_updateOrganizationDataSource", data);
      }, "organizationDataSources - updateOrganizationDataSource");
    },
    async updateOrganizationDataSourceUpdateStandardDataSourceFlag(context, organizationDataSource) {
      return timeOperation(async () => {
        const formattedUrl = `${organizationDataSourcesUrl}/${Number(organizationDataSource?.RootId || 0)}/updatestandarddatasourceflag`;
        const data = await context.dispatch(ApiRequest, {Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationDataSource});
        context.commit("_updateOrganizationDataSourceIsStandardDataSource", data);
        return data;
      }, "organizationDataSources - updateOrganizationDataSourceUpdateStandardDataSourceFlag");
    },
    async enableOrganizationDataSource(context, organizationDataSource) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDataSourcesUrl}/${Number(organizationDataSource?.RootId || 0)}/updateenabledstatus`;
        await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...organizationDataSource, Enabled: true } });
      }, "organizationDataSources - enableOrganizationDataSource");
    },
    async disableOrganizationDataSource(context, organizationDataSource) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDataSourcesUrl}/${Number(organizationDataSource?.RootId || 0)}/updateenabledstatus`;
        await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...organizationDataSource, Enabled: false } });
      }, "organizationDataSources - disableOrganizationDataSource");
    },
    async removeOrganizationDataSource(context, organizationDataSource) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDataSourcesUrl}/${Number(organizationDataSource?.RootId || 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeOrganizationDataSource", organizationDataSource);
      }, "organizationDataSources - removeOrganizationDataSource");
    },
  },
};
