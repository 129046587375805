import {
  AuditLogsRecordType,
  ChatsRecordType,
  DocumentLogRecordType,
  DocumentsRecordType,
  MessagesRecordType,
  NotesRecordType,
  OrderDocumentRecordType,
  OrdersRecordType,
  SharedDataLogRecordType,
  VendorOrdersRecordType,
  WorkflowLogRecordType,
  WorkflowNotificationsRecordType,
  WorkflowRulesRecordType,
  WorkflowTasksRecordType,
  UserSearchSettingsLogRecordType,
  UserAccessSettingsLogRecordType,
  UserConfigSettingsLogRecordType,
  OrganizationContactCompanyRecordType,
  OrganizationContactPersonRecordType,
  OrderContactCompanyRecordType,
  OrderContactPersonRecordType,
  WorkflowTaskTemplatesRecordType,
  VendorOrderEntriesRecordType,
  VendorOrderOfferingsRecordType,
  EXTERNAL_DATA_SOURCE_BASE_ROOT_ID,
} from "@/lib/settings";
import _ from "lodash";

export function getFilteredDataSources(dataSources, isEditingLogViews, includeExternal, useProductDataSources) {
  let result = [];
  if (isEditingLogViews) {
    dataSources?.forEach((dataSource) => {
      switch (dataSource.Type ?? "") {
        case OrdersRecordType:
        case DocumentsRecordType:
        case VendorOrdersRecordType:
        case MessagesRecordType:
        case ChatsRecordType:
        case NotesRecordType:
        case WorkflowTasksRecordType:
        case WorkflowRulesRecordType:
        case WorkflowNotificationsRecordType:
        case OrderDocumentRecordType:
        case AuditLogsRecordType:
        case DocumentLogRecordType:
        case SharedDataLogRecordType:
        case WorkflowLogRecordType:
        case UserSearchSettingsLogRecordType:
        case UserAccessSettingsLogRecordType:
        case UserConfigSettingsLogRecordType:
        case OrganizationContactCompanyRecordType:
        case OrganizationContactPersonRecordType:
        case OrderContactCompanyRecordType:
        case OrderContactPersonRecordType:
        case WorkflowTaskTemplatesRecordType:
        case VendorOrderEntriesRecordType:
        case VendorOrderOfferingsRecordType:
          result.push(_.cloneDeep(dataSource));
          break;
      }
      if (includeExternal) {
        switch (dataSource.Type ?? "") {
          case OrdersRecordType:
          case VendorOrdersRecordType:
            {
              let externalDataSource = _.cloneDeep(dataSource);
              externalDataSource.RootId = addBaseRootId(externalDataSource.RootId, true);
              externalDataSource.IsExternal = true;
              externalDataSource.Name = `External - ${externalDataSource.Name}`;
              let dataSourceFields = (useProductDataSources ? externalDataSource?.ProductDataSourceFields : externalDataSource?.OrganizationDataSourceFields) ?? [];
              dataSourceFields.forEach((dataSourceField) => {
                dataSourceField.RootId = addBaseRootId(dataSourceField.RootId, true);
                dataSourceField.IsExternal = true;
              });
              result.push(_.cloneDeep(externalDataSource));
            }
            break;
        }
      }
    });
  } else {
    dataSources?.forEach((dataSource) => {
      switch (dataSource.Type ?? "") {
        case OrdersRecordType:
        case VendorOrdersRecordType:
        case DocumentsRecordType:
        case WorkflowTasksRecordType:
        case WorkflowRulesRecordType:
        case WorkflowNotificationsRecordType:
        case OrganizationContactCompanyRecordType:
        case OrganizationContactPersonRecordType:
        case OrderContactCompanyRecordType:
        case OrderContactPersonRecordType:
        case WorkflowTaskTemplatesRecordType:
        case VendorOrderEntriesRecordType:
        case VendorOrderOfferingsRecordType:
          result.push(_.cloneDeep(dataSource));
          break;
      }
      if (includeExternal) {
        switch (dataSource.Type ?? "") {
          case OrdersRecordType:
          case VendorOrdersRecordType:
            {
              let externalDataSource = _.cloneDeep(dataSource);
              externalDataSource.RootId = addBaseRootId(externalDataSource.RootId, true);
              externalDataSource.IsExternal = true;
              externalDataSource.Name = `External - ${externalDataSource.Name}`;
              let dataSourceFields = (useProductDataSources ? externalDataSource?.ProductDataSourceFields : externalDataSource?.OrganizationDataSourceFields) ?? [];
              dataSourceFields.forEach((dataSourceField) => {
                dataSourceField.RootId = addBaseRootId(dataSourceField.RootId, true);
                dataSourceField.IsExternal = true;
              });
              result.push(_.cloneDeep(externalDataSource));
            }
            break;
        }
      }
    });
  }
  return result;
}

export function getFilteredFormDataSources(dataSources) {
  let result = [];
  dataSources?.forEach((dataSource) => {
    switch (dataSource.Type ?? "") {
      case OrdersRecordType:
      case DocumentsRecordType:
      case VendorOrdersRecordType:
      case WorkflowTasksRecordType:
      case OrganizationContactCompanyRecordType:
      case OrganizationContactPersonRecordType:
      case OrderContactCompanyRecordType:
      case OrderContactPersonRecordType:
      case WorkflowTaskTemplatesRecordType:
      case VendorOrderEntriesRecordType:
      case VendorOrderOfferingsRecordType:
        result.push(_.cloneDeep(dataSource));
        break;
      case MessagesRecordType:
      case ChatsRecordType:
      case NotesRecordType:
      case WorkflowRulesRecordType:
      case WorkflowNotificationsRecordType:
      case OrderDocumentRecordType:
      case AuditLogsRecordType:
      case DocumentLogRecordType:
      case SharedDataLogRecordType:
      case WorkflowLogRecordType:
      case UserSearchSettingsLogRecordType:
      case UserAccessSettingsLogRecordType:
      case UserConfigSettingsLogRecordType:
      default:
        break;
    }
  });
  return result;
}

export function getFilteredStandardDataSources(dataSources) {
  let result = [];
  dataSources?.forEach((dataSource) => {
    if (dataSource?.IsStandardDataSource) {
      result.push(_.cloneDeep(dataSource));
    }
  });
  return result;
}

export function getFilteredWorkflowDataSources(dataSources) {
  let result = [];
  dataSources?.forEach((dataSource) => {
    switch (dataSource.Type ?? "") {
      case OrdersRecordType:
      case VendorOrdersRecordType:
      case DocumentsRecordType:
      case OrganizationContactCompanyRecordType:
      case OrganizationContactPersonRecordType:
      case OrderContactCompanyRecordType:
      case OrderContactPersonRecordType:
      case VendorOrderEntriesRecordType:
        result.push(_.cloneDeep(dataSource));
        break;
      case MessagesRecordType:
      case ChatsRecordType:
      case NotesRecordType:
      case OrderDocumentRecordType:
      case AuditLogsRecordType:
      case DocumentLogRecordType:
      case SharedDataLogRecordType:
      case WorkflowLogRecordType:
      case UserSearchSettingsLogRecordType:
      case UserAccessSettingsLogRecordType:
      case UserConfigSettingsLogRecordType:
      case WorkflowTasksRecordType:
      case WorkflowRulesRecordType:
      case WorkflowNotificationsRecordType:
      case WorkflowTaskTemplatesRecordType:
      case VendorOrderOfferingsRecordType:
        break;
    }
  });
  return result;
}

export function addBaseRootId(rootId, isExternal) {
  rootId = Number(rootId ?? 0);
  isExternal = isExternal ?? false;
  if (rootId < EXTERNAL_DATA_SOURCE_BASE_ROOT_ID && isExternal) {
    rootId += EXTERNAL_DATA_SOURCE_BASE_ROOT_ID;
  }
  return rootId;
}

export function removeBaseRootId(rootId) {
  rootId = Number(rootId ?? 0);
  if (rootId > EXTERNAL_DATA_SOURCE_BASE_ROOT_ID) {
    rootId -= EXTERNAL_DATA_SOURCE_BASE_ROOT_ID;
  }
  return rootId;
}

export function containsBaseRootId(rootId) {
  return rootId > EXTERNAL_DATA_SOURCE_BASE_ROOT_ID;
}

export function isStandardDataSourceType(type) {
  switch (type ?? "") {
    case OrdersRecordType:
    case DocumentsRecordType:
    case VendorOrdersRecordType:
    case WorkflowTasksRecordType:
      return true;
    case MessagesRecordType:
    case ChatsRecordType:
    case NotesRecordType:
    case WorkflowRulesRecordType:
    case WorkflowNotificationsRecordType:
    case OrderDocumentRecordType:
    case AuditLogsRecordType:
    case DocumentLogRecordType:
    case SharedDataLogRecordType:
    case WorkflowLogRecordType:
    case UserSearchSettingsLogRecordType:
    case UserAccessSettingsLogRecordType:
    case UserConfigSettingsLogRecordType:
    case OrganizationContactCompanyRecordType:
    case OrganizationContactPersonRecordType:
    case OrderContactCompanyRecordType:
    case OrderContactPersonRecordType:
    case WorkflowTaskTemplatesRecordType:
    case VendorOrderEntriesRecordType:
    case VendorOrderOfferingsRecordType:
      break;
  }
  return false;
}

export function isFormDataSourceType(type) {
  switch (type ?? "") {
    case OrdersRecordType:
    case DocumentsRecordType:
    case VendorOrdersRecordType:
    case WorkflowTasksRecordType:
    case OrganizationContactCompanyRecordType:
    case OrganizationContactPersonRecordType:
    case OrderContactCompanyRecordType:
    case OrderContactPersonRecordType:
    case WorkflowTaskTemplatesRecordType:
    case VendorOrderEntriesRecordType:
    case VendorOrderOfferingsRecordType:
      return true;
    case MessagesRecordType:
    case ChatsRecordType:
    case NotesRecordType:
    case WorkflowRulesRecordType:
    case WorkflowNotificationsRecordType:
    case OrderDocumentRecordType:
    case AuditLogsRecordType:
    case DocumentLogRecordType:
    case SharedDataLogRecordType:
    case WorkflowLogRecordType:
    case UserSearchSettingsLogRecordType:
    case UserAccessSettingsLogRecordType:
    case UserConfigSettingsLogRecordType:
      break;
  }
  return false;
}

export function isLinksDataSourceType(type) {
  switch (type ?? "") {
    case DocumentsRecordType:
      return true;
    case OrdersRecordType:
    case VendorOrdersRecordType:
    case WorkflowTasksRecordType:
    case MessagesRecordType:
    case ChatsRecordType:
    case NotesRecordType:
    case WorkflowRulesRecordType:
    case WorkflowNotificationsRecordType:
    case OrderDocumentRecordType:
    case AuditLogsRecordType:
    case DocumentLogRecordType:
    case SharedDataLogRecordType:
    case WorkflowLogRecordType:
    case UserSearchSettingsLogRecordType:
    case UserAccessSettingsLogRecordType:
    case UserConfigSettingsLogRecordType:
    case OrganizationContactCompanyRecordType:
    case OrganizationContactPersonRecordType:
    case OrderContactCompanyRecordType:
    case OrderContactPersonRecordType:
    case WorkflowTaskTemplatesRecordType:
    case VendorOrderEntriesRecordType:
    case VendorOrderOfferingsRecordType:
      break;
  }
  return false;
}
